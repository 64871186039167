import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { PromiseState } from '../asyncController/types';

let props: {
  __promise: PromiseState<unknown>;
} = {
  __promise: {},
};

let flowAPI: ControllerFlowAPI;
export const setControllerFlowApi = (api: ControllerFlowAPI) => {
  flowAPI = api;
};

export const setControllerProps = (data: object) => {
  props = { ...props, ...data };
  return flowAPI.controllerConfig.setProps(data);
};

export const getControllerProps = () => {
  return props;
};

export const getFlowAPI = () => {
  return flowAPI;
};
