import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ICategoriesContext } from './interfaces';
import { requestCategoriesHttp } from './api/requestCategories';
import { WarmupData } from '../../../services/WarmupData';
import handleCategoriesAfterLogin from './helpers/handleCategoriesAfterLogin';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';

const WARMUP_DATA_KEY = 'programsCategories';
export let CATEGORIES_DATA: ICategoriesContext = null;

export const getCategoriesData = async (
  flowAPI: ControllerFlowAPI,
): Promise<ICategoriesContext> => {
  let categories = null;

  if (CATEGORIES_DATA) {
    return CATEGORIES_DATA;
  }

  try {
    categories = await requestCategoriesHttp(flowAPI);

    CATEGORIES_DATA = {
      categoriesData: {
        ...categories,
      },
    };
  } catch (error) {
    console.error(error);
    handleError({ error, context: 'getCategories' });
  }

  return CATEGORIES_DATA;
};

export const getCategoriesInitialData = async function (
  flowAPI: ControllerFlowAPI,
): Promise<ICategoriesContext> {
  const warmupData = new WarmupData(flowAPI);
  const ssrData = warmupData.get(WARMUP_DATA_KEY);

  void handleCategoriesAfterLogin();

  if (ssrData) {
    return ssrData;
  }

  const categories = await getCategoriesData(flowAPI);

  warmupData.set(WARMUP_DATA_KEY, categories);

  return categories;
};
