import { PromiseState } from './types';
import {
  getControllerProps,
  setControllerProps,
} from '../controllerProps/controllerProps';

const COLLECT_GARBAGE_AFTER = 3000;

let ids: string[] = [];

export const asyncControllerFunction = <
  T extends (...args: any[]) => Promise<any>,
>(
  fn: T,
): T => {
  return ((id: string, ...args) => {
    type State = PromiseState<ReturnType<T>>;
    const promiseState = getControllerProps()?.__promise;

    const { deleteIds, remainingIds } = ids.reduce(
      (acc, lId) => {
        const state = promiseState[lId];

        if (
          state.status !== 'pending' &&
          Date.now() - state.time > COLLECT_GARBAGE_AFTER
        ) {
          acc.deleteIds.push(lId);
        } else {
          acc.remainingIds.push(lId);
        }
        return acc;
      },
      {
        deleteIds: [] as string[],
        remainingIds: [id] as string[], // add current id to remainingIds
      },
    );

    const setPromiseProps = (state: State) => {
      const currentState = getControllerProps().__promise || {};
      // remove deleted ids from the state
      const __promiseState = Object.fromEntries(
        Object.entries(currentState).filter(([key]) => {
          return !deleteIds.includes(key);
        }),
      );
      setControllerProps({
        __promise: {
          ...__promiseState,
          ...state,
        },
      });
    };

    ids = remainingIds;

    setPromiseProps({
      [id]: {
        status: 'pending',
      },
    } as State);

    fn(...args)
      .then((result) => {
        setPromiseProps({
          [id]: {
            status: 'resolved',
            time: Date.now(),
            result,
          },
        } as State);
        return result;
      })
      .catch((error) => {
        setPromiseProps({
          [id]: {
            status: 'rejected',
            time: Date.now(),
            error,
          },
        } as State);
        throw error;
      });
  }) as T;
};
