import { ILoadingProps, ILoadingStatus } from './interfaces';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

let loading = null;

export function loadingPropsMap(flowAPI: ControllerFlowAPI): ILoadingProps {
  if (loading) {
    return loading;
  }

  loading = {
    isLoading: false,
    showLoader() {
      flowAPI.controllerConfig.setProps({
        isLoading: true,
        loadingStatus: ILoadingStatus.DEFAULT,
      } as Partial<ILoadingProps>);
      loading.isLoading = true;
    },
    hideLoader(loadingStatus = ILoadingStatus.OK) {
      flowAPI.controllerConfig.setProps({
        isLoading: false,
        loadingStatus,
      } as Partial<ILoadingProps>);
      loading.isLoading = false;
    },
  };

  return loading;
}
