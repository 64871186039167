import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  PAID_PLANS_APP_ID,
  PP_SECTION_ID,
} from '../../../../editor/app-config';

export async function isPaidPlansInstalled(flowAPI: ControllerFlowAPI) {
  return flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: PAID_PLANS_APP_ID,
    sectionId: PP_SECTION_ID,
  });
}
