import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { syncInstance } from '../../../services/instance';
import groupBy from 'lodash/groupBy';

const onLoginHandlers = [];

export enum IOnLoginHandlerPriority {
  'FIRST' = 'FIRST',
  'SECONDARY' = 'SECONDARY',
  'REST' = 'REST',
}

export const addOnLoginHandler = ({
  priority,
  handler,
}: {
  priority: IOnLoginHandlerPriority;
  handler: (flowAPI: ControllerFlowAPI) => Promise<void>;
}) => {
  onLoginHandlers.push({ priority, handler });
};

/*
  Base onLogin handler.
  Its required to update user first before request any other data,
    so to do it properly we need a single place to handle all login-related logic.
  Also, here we can call additional handlers only if it's really needed.
 */

export async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async () => {
    syncInstance(flowAPI);

    const onLoginGroups = groupBy(onLoginHandlers, 'priority');
    const getHandlersByCategory = (items: any[]) => {
      return (items || []).map((item) => item.handler(flowAPI));
    };

    await Promise.all(
      getHandlersByCategory(onLoginGroups[IOnLoginHandlerPriority.FIRST]),
    );
    await Promise.all(
      getHandlersByCategory(onLoginGroups[IOnLoginHandlerPriority.SECONDARY]),
    );
    await Promise.all(
      getHandlersByCategory(onLoginGroups[IOnLoginHandlerPriority.REST]),
    );
  });
}
