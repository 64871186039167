import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';

import { ILocalStorageProps } from './interfaces';

export function localStoragePropsMap(
  flowAPI: ControllerFlowAPI,
): ILocalStorageProps {
  return {
    setItem(key, value) {
      try {
        const _val = JSON.stringify(value);
        flowAPI.controllerConfig?.platformAPIs?.storage?.local?.setItem(
          key,
          _val,
        );
      } catch (error) {
        handleError({ error, context: 'localStorage.set' });
      }
    },
    requestItem(key) {
      let response = null;

      try {
        response =
          flowAPI.controllerConfig?.platformAPIs?.storage?.local?.getItem(key);
      } catch (error) {
        handleError({ error, context: 'localStorage.get' });
      }

      flowAPI.controllerConfig.setProps({
        storage: {
          [key]: response,
        },
      });

      return response;
    },
  };
}
