import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { requestCategoriesHttp } from '../api/requestCategories';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';
import {
  addOnLoginHandler,
  IOnLoginHandlerPriority,
} from '../../../GeneralDataProvider/helpers/onLogin';

export default function handleCategoriesAfterLogin() {
  addOnLoginHandler({
    priority: IOnLoginHandlerPriority.SECONDARY,
    handler: async (flowAPI: ControllerFlowAPI) => {
      let categories;

      try {
        categories = await requestCategoriesHttp(flowAPI);
      } catch (error) {
        const resolvedError = flowAPI.errorHandler.getResolvedError(error);
        handleError({
          error,
          resolvedError,
          context: 'onLogin.getCategories',
        });
      }

      flowAPI.controllerConfig.setProps({
        categoriesData: {
          ...categories,
        },
      });
    },
  });
}
