import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { request } from '../../../../services/request';
import { listCategories } from '@wix/ambassador-achievements-categories-v3-category/http';
import { shouldReportErrorMonitor } from '../../Challenge/helpers/shouldReportErrorMonitor';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';

export async function requestCategoriesHttp(flowAPI: ControllerFlowAPI) {
  try {
    const categories =
      (
        await request({
          flowAPI,
          options: listCategories({}),
          errorOptions: {
            errorCodesMap: { applicationError: {} },
          },
        })
      )?.data?.categories || [];
    return { categories };
  } catch (error) {
    const resolvedError = flowAPI.errorHandler.getResolvedError(error);
    handleError({
      error,
      resolvedError,
      context: 'GetCategoriesList',
      preventErrorMonitorReport: !shouldReportErrorMonitor(error),
    });
    return {
      categories: undefined,
    };
  }
}
