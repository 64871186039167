import { asyncControllerFunction } from './asyncControllerFunction';
import { request as httpRequest } from '../request';
import { getFlowAPI } from '../controllerProps/controllerProps';
import { ErrorCodesMapConditionalEnforcement } from '@wix/error-handler/dist/types/types/ErrorCodesMap';
import type { HttpResponse } from '@wix/fe-essentials/http-client';
import { ExtractRequestOptionsData } from './types';

export const asyncControllerAmbassadorRequest = <
  Fn extends (...args: any[]) => any,
>(
  request: Fn,
  errorOptions: {
    errorCodesMap: Omit<
      ErrorCodesMapConditionalEnforcement<
        HttpResponse<ExtractRequestOptionsData<ReturnType<Fn>>>
      >,
      'validationError'
    >;
  },
) => {
  return asyncControllerFunction(async (data: any) => {
    const response = await httpRequest({
      flowAPI: getFlowAPI(),
      options: request(data),
      errorOptions,
    });
    return response.data;
  });
};
