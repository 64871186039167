import { IProvidersProps } from './interfaces';
import { locationProviderPropsMap } from '../Location/locationProviderPropsMap';
import { generalDataProviderPropsMap } from '../GeneralDataProvider/generalDataProviderPropsMap';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { loadingPropsMap } from '../Loading/loadingPropsMap';
import { errorHandlerPropsMap } from '../ErrorHandler/errorHandlerPropsMap';
import { localStoragePropsMap } from '../LocalStorage/localStoragePropsMap';
import { monitoringPropsMap } from '../Monitoring/MonitoringPropsMap';
import { toastPropsMap } from '../ToastContext/toastPropsMap';
import { asyncControllerAmbassadorRequest } from '../../services/asyncController/asyncControllerAmbassadorRequest';
import { issueCertificate } from '@wix/ambassador-challenges-v1-participant/http';
import { asyncControllerFunction } from '../../services/asyncController/asyncControllerFunction';
import { defaultErrorOptions } from '../../utils/defaultErrorOptions';
import { getControllerPromiseError } from '../../services/asyncController/getControllerPromiseError';

interface IBaseProviders {
  flowAPI: ControllerFlowAPI;
}

export const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    return { ...obj, ...item };
  }, {});

const initializationErrors = [];
const handlePromiseError = async (p: Promise<any>) => {
  try {
    return await p;
  } catch (e) {
    console.error(e);
    initializationErrors.push(e);
    return {
      initialisationErrors: initializationErrors,
    };
  }
};

export const arrayToObjectAsync = async (
  array: (Promise<any> | object | null)[],
) => {
  return arrayToObject(
    await Promise.all(
      array.filter((item) => item !== null).map(handlePromiseError),
    ),
  );
};

export async function getBaseProvidersData({
  flowAPI,
}: IBaseProviders): Promise<Partial<IProvidersProps>> {
  const syncProvidersData = {
    ...locationProviderPropsMap(flowAPI),
    ...loadingPropsMap(flowAPI),
    ...localStoragePropsMap(flowAPI),
    ...monitoringPropsMap(flowAPI),
    ...toastPropsMap(flowAPI),
    issueCertificate: asyncControllerAmbassadorRequest(
      issueCertificate,
      defaultErrorOptions,
    ),
    getResolvedError: asyncControllerFunction(async (error: string) => {
      const resolvedError = flowAPI.errorHandler.getResolvedError(
        getControllerPromiseError(error),
      );
      delete resolvedError.action?.onClick; // on click is not serializable
      return resolvedError;
    }),
  };

  const asyncParallelProviders: Promise<any>[] = [
    generalDataProviderPropsMap(flowAPI),
    errorHandlerPropsMap(flowAPI),
  ];

  const asyncProvidersData = arrayToObject(
    await Promise.all(asyncParallelProviders),
  );

  return { ...syncProvidersData, ...asyncProvidersData };
}
