import { IGeneralDataContext } from './GeneralDataContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { extractUoUParams } from '@wix/ricos';
import { isMASectionInstalled } from '../../services/isMASectionInstalled';
import {
  MY_WALLET_APP_ID,
  MY_WALLET_SECTION_ID,
  MY_WALLET_WIDGET_ID,
} from '../../editor/app-config';
import { isParticipantPageAvailable } from '../Location/helpers/generateLinksToProgramForList';
import { handleUserLogin } from './helpers/onLogin';

export interface IGeneralDataProviderProps extends IGeneralDataContext {}

export async function generalDataProviderPropsMap(
  flowAPI: ControllerFlowAPI,
): Promise<IGeneralDataProviderProps> {
  const { appDefinitionId } = flowAPI.environment;
  const defaultBIParams = extractUoUParams({
    platformAPIs: flowAPI.controllerConfig.platformAPIs,
  });
  const regionalSettings =
    flowAPI.controllerConfig.wixCodeApi.site.regionalSettings;
  const instance =
    flowAPI.controllerConfig.wixCodeApi.site.getAppToken(appDefinitionId);

  let instanceParsed = { instanceId: null };

  // https://wix.slack.com/archives/CAKBA7TDH/p1643623637998229
  flowAPI.controllerConfig.setProps({
    fitToContentHeight: true,
  });

  try {
    instanceParsed = JSON.parse(
      atob(instance.substr(instance.indexOf('.') + 1)),
    );
  } catch (err) {
    console.error(err);
  }

  const [isMyWalletInstalled] = await Promise.all([
    isMASectionInstalled(
      MY_WALLET_APP_ID,
      MY_WALLET_SECTION_ID,
      MY_WALLET_WIDGET_ID,
      flowAPI,
    ),
  ]);

  await handleUserLogin(flowAPI);

  return {
    instance,
    instanceId: instanceParsed.instanceId,
    msid: defaultBIParams.metaSiteId,
    defaultBIParams,
    startTimeOfRender: Date.now(),
    regionalSettings,
    isMyWalletInstalled,
    isParticipantPageAvailable: await isParticipantPageAvailable(flowAPI),
  };
}
