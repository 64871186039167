import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengesListWithMocks } from '../data';
import {
  addOnLoginHandler,
  IOnLoginHandlerPriority,
} from '../../../GeneralDataProvider/helpers/onLogin';

export default function handleChallengesAfterLogin() {
  addOnLoginHandler({
    priority: IOnLoginHandlerPriority.SECONDARY,
    handler: async (flowAPI: ControllerFlowAPI): Promise<void> => {
      flowAPI.controllerConfig.setProps({
        challengesListData: {
          ...(await getChallengesListWithMocks(flowAPI)),
        },
      });
    },
  });
}
